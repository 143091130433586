import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-boost";
import { QueryClient, QueryClientProvider } from "react-query";
// Styles
// CoreUI Icons Set
import "@coreui/icons/css/all.min.css";
// Import Flag Icons Set
import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.css";

// Containers
import { DefaultLayout } from "./containers";
// Pages
import { Landing, Page404, Page500 } from "./views/Pages";

const client = new ApolloClient({
  uri: "https://aaa-server.herokuapp.com",
  request: async (operation) => {
    // const token = await localStorage.getItem('aa-token');
    const token = process.env.REACT_APP_TOKEN;
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });
  },
});

const queryClient = new QueryClient();

// import { renderRoutes } from 'react-router-config';

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Switch>
              <Route exact path="/landing" name="Landing Page" component={Landing} />
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/500" name="Page 500" component={Page500} />
              <Route path="/" name="Home" component={DefaultLayout} />
            </Switch>
          </Router>
        </QueryClientProvider>
      </ApolloProvider>
    );
  }
}

export default App;
