import React, { Component, Fragment } from "react";
import { Nav, NavLink, Button } from "reactstrap"; // NavItem

import { AppNavbarBrand } from "@coreui/react";

import logo from "../../assets/img/logoline_blanco.png";

import Modal from "react-responsive-modal";

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      width: 0,
      height: 0,
      open: false,
      meRole: this.props.role,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.logout = this.logout.bind(this);
  }

  logout() {
    localStorage.removeItem("aa-token");
    localStorage.removeItem("aa-role");
    this.props.history.push("/login");
  }

  closeModal() {
    this.setState({ open: !this.state.open });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  toggleNavbar() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  showUsers(role) {
    switch (role) {
      case "SYSTEMADMIN":
        return true;
      case "ADMINISTRATOR":
        return true;
      case "GERENCIA":
        return true;
      case "MAYORISTA":
        return false;
      case "AGENTE":
        return false;
      case "AGENCIADEVIAJES":
        return false;
      case "USER":
        return false;
      default:
        return false;
    }
  }
  showSubscribers(role) {
    switch (role) {
      case "SYSTEMADMIN":
        return true;
      case "ADMINISTRATOR":
        return true;
      case "GERENCIA":
        return true;
      case "MAYORISTA":
        return false;
      case "AGENTE":
        return false;
      case "AGENCIADEVIAJES":
        return false;
      case "USER":
        return false;
      default:
        return false;
    }
  }
  showBlog(role) {
    switch (role) {
      case "SYSTEMADMIN":
        return true;
      case "ADMINISTRATOR":
        return true;
      case "GERENCIA":
        return true;
      case "MAYORISTA":
        return true;
      case "AGENTE":
        return false;
      case "AGENCIADEVIAJES":
        return false;
      case "USER":
        return false;
      default:
        return false;
    }
  }
  showAppointments(role) {
    switch (role) {
      case "SYSTEMADMIN":
        return true;
      case "ADMINISTRATOR":
        return true;
      case "GERENCIA":
        return true;
      case "MAYORISTA":
        return false;
      case "AGENTE":
        return false;
      case "AGENCIADEVIAJES":
        return false;
      case "USER":
        return false;
      default:
        return false;
    }
  }

  render() {
    const { width, open } = this.state;
    const role = localStorage.getItem("aa-role");
    return (
      <Fragment>
        <div className="inner-container flex w-100 justify-content-between">
          <div className="nav-logo">
            <AppNavbarBrand
              full={{ src: logo, width: 280, alt: "A&A Tours Logo" }}
              minimized={{ src: logo, width: 110, alt: "A&A Tours Logo" }}
              href="/home"
            />
          </div>

          {this.props.isAdminPage ? (
            <Nav className="m-auto" navbar>
              {this.showUsers(role) ? (
                <NavLink href="/admin/users" className="nav-link">
                  Users
                </NavLink>
              ) : null}
              {this.showSubscribers(role) && (
                <NavLink href="/admin/subscribers" className="nav-link">
                  Subscribers
                </NavLink>
              )}
              <NavLink href="/admin/promo" className="nav-link">
                Promo
              </NavLink>
              <NavLink href="/admin/ready2go" className="nav-link">
                Ready2Go
              </NavLink>
              <NavLink href="/admin/destinations/royal" className="nav-link">
                {" "}
                Hoteles y Excursiones{" "}
              </NavLink>
              <NavLink href="/admin/flotas/royal" className="nav-link">
                {" "}
                Cruceros{" "}
              </NavLink>
              <NavLink href="/admin/searched" className="nav-link">
                Searched
              </NavLink>
              {this.showBlog(role) && (
                <NavLink href="/admin/blog" className="nav-link">
                  Blog
                </NavLink>
              )}
            </Nav>
          ) : (
            <Nav navbar className="navigation">
              <NavLink href="/blog" className="nav-link">
                ENTREVISTAS
              </NavLink>
              {width < 1200 && (
                <NavLink
                  className="nav-link"
                  target="_blank"
                  href="https://la.studio.chubb.com/pr/aatours/travel/launchstage/es-PR"
                >
                  SEGURO DE VIAJES
                </NavLink>
              )}
              <NavLink href="/contact" className="nav-link">
                CONTACTO
              </NavLink>
            </Nav>
          )}
          <div className="flex nav-actions">
            {width > 1200 &&
              (this.props.isAdminPage ? (
                <Button color="primary" className="btn-pill" onClick={() => this.logout()}>
                  Log Out
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="hero-btn"
                  target="_blank"
                  href="https://la.studio.chubb.com/pr/aatours/travel/launchstage/es-PR"
                >
                  SEGURO DE VIAJES
                </Button>
              ))}
          </div>
        </div>

        <Modal open={open} onClose={() => this.closeModal()}>
          <img
            className="ofertas-img"
            style={{ marginTop: "3.5em" }}
            src="assets/img/cruises/ofertas/anuncio-cruceros.jpg"
            alt="quinceaneras"
          />
          <img
            className="ofertas-img"
            style={{ marginTop: "3.5em" }}
            src="assets/img/cruises/ofertas/anuncio-terrestre.jpg"
            alt="quinceaneras"
          />
        </Modal>
      </Fragment>
    );
  }
}

export default DefaultHeader;
