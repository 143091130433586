import gql from 'graphql-tag';

export default gql`
  mutation CreateSubscriptor($email: String) {
    createSubscriptor(email: $email) {
      id
      email
    }
  }
`;
