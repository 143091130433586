import React, { Component, Fragment } from "react";
import { Button, Col, Container, Row, NavLink, Input } from "reactstrap";
import Logo from "../../assets/img/logoline_blanco.png";
import facebook from "../../assets/img/Facebook.png";
// import youtube from '../../assets/img/YouTube.png';
import instagram from "../../assets/img/instagram.png";
import { graphql, compose } from "react-apollo";
import SweetAlert from "react-bootstrap-sweetalert";

import CreateSubscriptor from "../../mutations/CreateSubscriptor";

import Modal from "react-responsive-modal";

class DefaultFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      email: "",
      open: false,
    };
  }

  closeModal() {
    this.setState({ open: !this.state.open });
  }

  render() {
    let { open } = this.state;
    return (
      <Fragment>
        {this.state.alert}
        <Container className="footer-bar">
          <Row className="container-smaller justify-content-between">
            <img src={Logo} className="footer-logo" alt="Logo" />
            <div className="social-media-group">
              <a
                href="https://www.facebook.com/aatourspr/ "
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="social-media-icons f-icon" src={facebook} alt="facebook-logo" />
              </a>
              {/* <a href="https://www.youtube.com/user/aatourspr" target="_blank" rel="noopener noreferrer">
                <img className="social-media-icons y-icon" src={youtube} alt="youtube-logo" />
              </a> */}
              <a
                href="https://www.instagram.com/aatourspr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="social-media-icons i-icon" src={instagram} alt="instagram-logo" />
              </a>
            </div>
            {/* <span className="ml-auto">A&A Tours &copy; 2020.</span> */}
          </Row>
        </Container>
        <Container className="footer-area">
          <Row className="container-smaller">
            <Col lg="4" className="footer-menu">
              <NavLink href="/login" className="footer-nav-link">
                AGENTES DE VIAJE
              </NavLink>
            </Col>
            <Col lg="4">
              <h6 className="footer-newsletter">NOTICIAS</h6>
              <p>Regístrate para recibir todas nuestras grandes ofertas directo a tu email.</p>
              <Input
                className="footer-subscribe"
                type="text"
                placeholder="E-mail"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <Button className="submit-subscribe" onClick={() => this._createSubscriptor()}>
                {">"}
              </Button>
            </Col>
          </Row>
          <Row className="container-smaller">
            <span className="footer-line"></span>
            <span className="footer-copyright">
              Copyright © 2024 por AA Tours. Todos los derechos reservados.
            </span>
          </Row>
        </Container>
        <Modal open={open} onClose={() => this.closeModal()}>
          <img
            className="ofertas-img"
            style={{ marginTop: "3.5em" }}
            src="assets/img/cruises/ofertas/anuncio-cruceros.jpg"
            alt="quinceaneras"
          />
          <img
            className="ofertas-img"
            style={{ marginTop: "3.5em" }}
            src="assets/img/cruises/ofertas/anuncio-terrestre.jpg"
            alt="quinceaneras"
          />
        </Modal>
      </Fragment>
    );
  }

  _createSubscriptor = async () => {
    if (this.state.email) {
      let { createSubscriptor } = this.props; // signup querys

      let variables = { email: this.state.email };

      let mutationResponse = await createSubscriptor({ variables });
      if (mutationResponse.data) {
        this.setState({
          email: "",
          alert: (
            <SweetAlert
              success
              title="Gracias!"
              onConfirm={() => {
                this.setState({ alert: null });
              }}
              closeOnClickOutside={true}
            >
              Ha sido registrado exitosamente.
            </SweetAlert>
          ),
        });
      } else {
        //console.log("Something went wrong with subscriptor creation!")
      }
    } else {
      this.setState({
        alert: (
          <SweetAlert
            danger
            title="Error!"
            onConfirm={() => {
              this.setState({ alert: null });
            }}
            closeOnClickOutside={true}
          >
            Un email es requerido para subscribirse.
          </SweetAlert>
        ),
      });
    }
  };
}

export default compose(graphql(CreateSubscriptor, { name: "createSubscriptor" }))(DefaultFooter);
