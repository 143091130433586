import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import { AppFooter, AppHeader } from "@coreui/react";
// sidebar nav config
// import navigation from '../../_nav';
// import navigationSA from '../../_navSA';
// routes config
import routes from "../../routes";

// import DefaultAside from './DefaultAside';
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];
    this.state = {
      isAdminPage: false,
      isAdmin: false,
    };
    this.logout = this.logout.bind(this);
    this.isAdminPage = this.isAdminPage.bind(this);
    this.isAdminCheck = this.isAdminCheck.bind(this);
  }

  isAdminPage(value) {
    this.setState({ isAdminPage: value });
  }

  isAdminCheck() {
    if (localStorage.getItem("aa-role")) {
      const role = localStorage.getItem("aa-role");
      return role === "ADMINISTRATOR";
    } else {
      this.props.history.replace("/login");
    }
  }

  logout() {
    localStorage.removeItem("aa-token");
    localStorage.removeItem("aa-role");
    window.location.reload();
  }

  render() {
    const { history } = this.props;

    const userRole = localStorage.getItem("aa-role") && localStorage.getItem("aa-role");

    // console.log("history", history.location.pathname);
    return (
      <div className="app" role="presentation">
        <AppHeader fixed>
          <Container className="navbar-container">
            <DefaultHeader history={history} isAdminPage={this.state.isAdminPage} role={userRole} />
          </Container>
        </AppHeader>
        <div className="app-body">
          <main className="main">
            <Container fluid className={history.location.pathname === "/search" ? "p-0" : ""}>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route key={idx} path={route.path} exact={route.exact} name={route.name} render={(props) => <route.component {...props} isAdminPage={this.isAdminPage} role={userRole} />} />
                  ) : null;
                })}
                <Redirect from="/" to="/home" />
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
