import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import './Landing.css'
import Logo from '../../../assets/img/logo.png'
// import bubbleNose from '../../../assets/img/nariz-land.png'

class Login extends Component {
  render() {
    return (
      <div className="app flex-row top-landing">
        <Container>
          <Row className="justify-content-center">
            <Col lg="5" md="6" sm="10">
              <div className="logo-container">
                <img src={Logo} alt="logo" width="100%" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
