import React from "react";
import Loadable from "react-loadable";
import DefaultLayout from "./containers/DefaultLayout";

function Loading() {
  return <div className="h-100vh" />;
}

const Login = Loadable({
  loader: () => import("./views/Login"),
  loading: Loading,
});

const Admin = Loadable({
  loader: () => import("./views/Admin"),
  loading: Loading,
});

const Blog = Loadable({
  loader: () => import("./views/Blog"),
  loading: Loading,
});

const Article = Loadable({
  loader: () => import("./views/Article"),
  loading: Loading,
});

const AdminDestinations = Loadable({
  loader: () => import("./views/AdminDestinations"),
  loading: Loading,
});

const AdminBlog = Loadable({
  loader: () => import("./views/AdminBlog"),
  loading: Loading,
});

const AdminFlotas = Loadable({
  loader: () => import("./views/AdminFlotas"),
  loading: Loading,
});

const AdminReady2Go = Loadable({
  loader: () => import("./views/AdminReady2Go"),
  loading: Loading,
});

const AdminSearched = Loadable({
  loader: () => import("./views/AdminSearched"),
  loading: Loading,
});

const AdminSubscriber = Loadable({
  loader: () => import("./views/AdminSubscriber"),
  loading: Loading,
});

const AdminUserManagement = Loadable({
  loader: () => import("./views/AdminUserManagement"),
  loading: Loading,
});

const AdminPromo = Loadable({
  loader: () => import("./views/AdminPromo"),
  loading: Loading,
});

const Agents = Loadable({
  loader: () => import("./views/Agents"),
  loading: Loading,
});

const AmaWaterways = Loadable({
  loader: () => import("./views/AmaWaterways"),
  loading: Loading,
});

const Azamara = Loadable({
  loader: () => import("./views/Azamara"),
  loading: Loading,
});

const Home = Loadable({
  loader: () => import("./views/Home"),
  loading: Loading,
});

const Celebrity = Loadable({
  loader: () => import("./views/Celebrity"),
  loading: Loading,
});

const Contact = Loadable({
  loader: () => import("./views/Contact"),
  loading: Loading,
});

const Cruises = Loadable({
  loader: () => import("./views/Cruises"),
  loading: Loading,
});

const Destinations = Loadable({
  loader: () => import("./views/Destinations"),
  loading: Loading,
});

const PaulGauguin = Loadable({
  loader: () => import("./views/PaulGauguin"),
  loading: Loading,
});

const Pullmantur = Loadable({
  loader: () => import("./views/Pullmantur"),
  loading: Loading,
});

const Royal = Loadable({
  loader: () => import("./views/Royal"),
  loading: Loading,
});

const Resorts = Loadable({
  loader: () => import("./views/Resorts"),
  loading: Loading,
});

const SearchResult = Loadable({
  loader: () => import("./views/SearchResult/SearchResult"),
  loading: Loading,
});
const PanaViajero = Loadable({
  loader: () => import("./views/PanaViajero/PanaViajero"),
  loading: Loading,
});

// const Test = Loadable({
//   loader: () => import('./views/AmaWaterwaysTest/AmaWaterwaysTest'),
//   loading: Loading,
// })

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: "/", name: "Home", component: DefaultLayout, exact: true },
  //Real Pages
  { path: "/login", name: "Login", component: Login },
  { path: "/blog", name: "Blog", component: Blog },
  { path: "/article/:id", name: "Article", component: Article },
  { path: "/admin/dashboard", name: "Admin", component: Admin },
  // { path: '/admin/appointments', name: 'AdminAppointments', component: AdminAppointments },
  {
    path: "/admin/destinations/:nickname",
    name: "AdminDestinations",
    component: AdminDestinations,
  },
  { path: "/admin/blog", name: "AdminBlog", component: AdminBlog },
  { path: "/admin/flotas/:nickname", name: "AdminFlotas", component: AdminFlotas },
  { path: "/admin/ready2go", name: "AdminReady2Go", component: AdminReady2Go },
  { path: "/admin/searched", name: "AdminSearched", component: AdminSearched },
  { path: "/admin/subscribers", name: "AdminSubscriber", component: AdminSubscriber },
  { path: "/admin/users", name: "AdminUserManagement", component: AdminUserManagement },
  { path: "/admin/promo", name: "AdminPromo", component: AdminPromo },
  { path: "/agents", name: "Agentes de Viaje", component: Agents },
  { path: "/amawaterways", name: "AmaWaterways", component: AmaWaterways },
  // { path: '/appointments', name: 'Appointments', component: Appointments },
  //{ path: '/test', name: 'AmaWaterwaysTest', component: Test },
  { path: "/azamara", name: "Azamara", component: Azamara },
  { path: "/home", name: "Home", component: Home },
  { path: "/celebrity", name: "Celebrity Cruises", component: Celebrity },
  { path: "/contact", name: "Contact", component: Contact },
  { path: "/cruises", name: "Cruises", component: Cruises },
  { path: "/destinations", name: "Destinations", component: Destinations },
  { path: "/paulgauguin", name: "Paul Gauguin Cruices", component: PaulGauguin },
  { path: "/pullmantur", name: "Pullmantur Cruceros", component: Pullmantur },
  { path: "/royal", name: "Royal Caribbean", component: Royal },
  { path: "/resorts", name: "Resorts", component: Resorts },
  {
    path: "/searchResult/:string/:arrival/:departure/:adults/:children/:price/:vacationType",
    name: "SearchResult",
    component: SearchResult,
  },
  { path: "/panaviajero", name: "PanaViajero", component: PanaViajero },
];

export default routes;
